.monthList {
  cursor: pointer;
}
.monthList > td:hover {
  color: #FF3333 !important;
}
g.MuiChartsLegend-root {
  margin-left: 1rem;
}
.greenlight {
  animation: greenlight linear forwards infinite 5s;
}
.occupied {
  cursor: pointer;
  /*background: rgb(26,221,179);
  background: linear-gradient(90deg, rgba(26,221,179,1) 0%, rgba(26,217,221,1) 39%, rgba(250,91,91,1) 100%);*/
  color: white !important;
  font-weight: 500;
  padding: 0.5rem;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 5px;
}
.left {
  cursor: pointer;
  /*background: rgb(221,26,211);
  background: linear-gradient(90deg, rgba(221,26,211,1) 0%, rgba(221,123,26,1) 39%, rgba(250,91,91,1) 100%);*/
  color: white !important;
  font-weight: 500;
  padding: 0.5rem;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 5px;
  margin-bottom: 0.2rem;
}
.occupiednoborder {
  cursor: pointer;
  /*background: rgb(26,221,179);
  background: linear-gradient(90deg, rgba(26,221,179,1) 0%, rgba(26,217,221,1) 39%, rgba(250,91,91,1) 100%);*/
  color: white !important;
  font-weight: 500;
  padding: 0.5rem 0 0.5rem 0.5rem;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.leftnoborder {
  cursor: pointer;
  /*background: rgb(221,26,211);
  background: linear-gradient(90deg, rgba(250,91,91,1) 0%, rgba(221,123,26,1) 39%, rgba(250,91,91,1) 100%);*/
  color: white !important;
  font-weight: 500;
  padding: 0.5rem 0.5rem 0.5rem 0;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 0.2rem;
}
.checkin {
  color: #32CD32;
  font-weight: bold;
  text-shadow: 1px 1px #010101;
}
.closebutton {
  color: #FF3333;
  font-size: 40px;
  cursor: pointer;
}
.closebutton:hover {
  animation: closehover linear forwards 1s;
}
@keyframes closehover {
  from {
    color: inherit;
    background-color: inherit;
  }
  to {
    color: white;
    background-color: red;
  }
}
@keyframes greenlight {
  0% {
    color: #009900;
  }
  50% {
    box-shadow:
    0 0 7px 4px #fff,  /* inner white */
    0 0 12px 7px #00ff00; /* middle magenta */
    color: #00ff00;
  }
  100% {
    color: #009900;
    box-shadow: none;
  }
}
.redlight {
  animation: redlight linear forwards infinite 5s;
}
@keyframes redlight {
  0% {
    color: #CC0000;
  }
  50% {
    box-shadow:
    0 0 7px 4px #fff,  /* inner white */
    0 0 12px 7px #ff3333; /* middle magenta */
    color: #FF3333;
  }
  100% {
    color: #CC0000;
    box-shadow: none;
  }
}
.nav-link{
  font-size: 22px !important;
  font-family: 'Hind', regular;
  color: #000 !important;
}
.nav-link.nav-mobile {
  color: #efefef !important;
}
.nav-link.active, .nav-mobile.nav-link.active{
  font-weight: bold;
  color: rgb(138,106,170) !important;
}
.nav-link.active:hover, .nav-mobile.nav-link.active:hover {
  color: rgb(138,106,170) !important;
}
.wrapperList {
  background-color: #fff;
  border: 1px solid black;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 5px;
  margin: 1rem;
  padding: 0.5rem;
  overflow: auto;

}
.settings {
  font-size: 35px;
}
.settings:hover {
  cursor: pointer;
  color:darkmagenta;
  animation: ruota 3s infinite ease-in-out forwards;
}
@keyframes ruota {
  0% {
    font-size: 35px;
    transform: rotate(0)
  }
  50% {
    font-size: 38px;
    transform: rotate(360deg);
  }
  100% {
    font-size: 35px;
    transform: rotate(0);
  }
}
.searchDiv {
  margin-top: 1px;
  border-radius: 5px;
  position: absolute;
  z-index: 999;
  min-width: 100%;
  display: block;
  background-color: lightblue;
  overflow-y: scroll;
}
.findDiv {
  z-index: 999;
  position: relative;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid black;
  color:#000;
}
.findDiv > div {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  display: inline;
}
.findDiv:hover, .findDiv.findSelected {
  border: 1px solid lightblue;
  background-color: #efefef;
}
.form-control.redborder {
  border-color: red; 
}
.login {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,104,121,1) 35%, rgba(0,212,255,1) 100%);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 10px;
  z-index: 2;
  position: relative;
  margin-top: 10%;
  margin-bottom: 15%;
}
.dashboard {
  border-radius: 10px;
  z-index: 2;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(221,217,221,1) 100%);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  margin-top: 10%;
  margin-bottom: 15%;
}
body {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(113,9,121,1) 35%, rgba(0,212,255,1) 100%);
}
.bodycolor > img {
  z-index: 1;
  position: absolute;
  top: 160px;
}
.insideLogin {
  padding: 10%;
}
.loginbutton {
  background: rgb(238,174,202);
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
}
.loginbutton:hover {
  background: rgb(238,174,174);
  background: radial-gradient(circle, rgba(238,174,174,1) 0%, rgba(148,233,197,1) 100%);   
  animation: bolda 0.5s ease-in-out forwards;
  border-color: black !important;
  font-weight: 400;
}
@keyframes bolda {
  from {
    font-weight: inherit;
  }
  to {
    font-weight: 600;
  }
}